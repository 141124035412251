import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';

import Article, {
  ArticleHeader, ArticleTitle, ArticleContent, ArticleParagraph, ArticleSummary,
} from 'components/Article';

import styles from './PressRelease.module.scss';

const PressRelease: React.FC = () => {
  const {
    image1,
    image2,
    image3,
  } = useStaticQuery(
    graphql`
        query {
            image1: file(relativePath: { eq: "article-1.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            image2: file(relativePath: { eq: "article-2.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            image3: file(relativePath: { eq: "article-3.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );

  return (
    <Article>
      <ArticleHeader>
        <ArticleTitle level={1}>
          Taïg Khris se lance un nouveau défi en créant Albums, un réseau social
          qui révolutionne le partage de nos photos et vidéos
        </ArticleTitle>
        <div className={styles.headerSeparator} />
        <div className={styles.meta}>
          <span className={styles.published}>February 11, 2020</span>
          <a href="/Albums.zip" download className={styles.downloadMedia}>
            Download media archive.zip
          </a>
        </div>
      </ArticleHeader>
      <ArticleContent>
        <ArticleSummary>
          Fort du constat qu’aucun acteur Français ou Européen n’a pour l’instant réussi à créer
          un réseau social de taille mondiale, l’entrepreneur Taïg Khris lance l’application
          “Albums” qui ambitionne de révolutionner le partage de photos et vidéos entre
          utilisateurs. La vie de millions de personnes est aujourd’hui exposée quotidiennement
          sur les réseaux sociaux sans qu’elles puissent réellement décider comment et avec qui
          partager leurs contenus. C’est pourquoi Albums souhaite devenir un “ Instagram “ made in
          Europe pour permettre à tous de reprendre un contrôle total sur les photos et vidéos
          qu’ils partagent. L’app Albums est 100% gratuite, sans publicité, et permet de gérer et
          partager d’une façon unique et sécurisée ses photos et vidéos.
        </ArticleSummary>
        <figure className={styles.image}>
          <GatsbyImage fluid={image1.childImageSharp.fluid} />
        </figure>

        <ArticleTitle level={2}>
          Partager plus de photos, en préservant sa vie privée
        </ArticleTitle>
        <ArticleParagraph>
          95% des photos et vidéos prises dorment aujourd’hui dans nos téléphones : par peur de
          l’usage qui pourrait en être fait, la majorité des détenteurs de smartphones choisissent
          uniquement de belles photos et peu personnelles afin de les poster sur les réseaux
          sociaux. Pour partager avec leurs proches et leurs amis, ils utilisent des apps de
          messageries qui ne sont pas adaptées à cet usage.
        </ArticleParagraph>
        <ArticleParagraph>
          Albums est le nouveau réseau social imaginé et développé par Taïg Khris, pour créer des
          albums photos et vidéos qui pourront être suivis publiquement par tous les utilisateurs
          ou bien partagés uniquement à des groupes d’amis. Albums redonne le contrôle à ses
          utilisateurs grâce à une personnalisation simple des modalités d&apos;accès de chaque
          album : accès temporaire ou illimité, en lecture seule ou collaboratif. Dans ce dernier
          cas de figure, tous les membres du groupe peuvent poster leurs propres photos et vidéos
          et interagir via des likes / des commentaires. La protection via des mots de passe
          permet également de masquer au choix certains albums et de les faire réapparaître à la
          demande.
        </ArticleParagraph>
        <ArticleParagraph>
          Conçu et construit pour préserver la vie privée de ses utilisateurs et le contenu de
          leurs albums, l’ensemble des informations recueillies et stockées sont cryptées. Les
          captures d’écrans sont également interdites et sanctionnées via l’application (blocage
          des utilisateurs dans l&apos;application). Albums souhaite être le premier acteur à
          créer un environnement de confiance où chacun peut partager sa vie privée avec les
          personnes qu’il aura réellement choisies.
        </ArticleParagraph>
        <figure className={styles.image}>
          <GatsbyImage fluid={image2.childImageSharp.fluid} />
        </figure>

        <ArticleTitle level={2}>
          Un réseau social viral et privé à la fois
        </ArticleTitle>
        <ArticleParagraph>
          A côté des albums privés, les utilisateurs peuvent créer des albums publics. Chaque
          membre d’Albums peut s’abonner aux albums publics de ses amis ou de nouvelles
          connaissances, ou suivre ses célébrités et marques préférées. Commentaires, likes : les
          albums publics permettent d’interagir avec leur créateur et de découvrir des
          personnalités intéressantes sans être dérangé par la pub. Car le réseau social Albums
          n’est pas construit sur ce modèle économique.
        </ArticleParagraph>
        <ArticleParagraph>
          “ Les réseaux sociaux les plus populaires basés sur la photo et vidéo sont devenus la
          représentation publique de la vie des gens. En réalité, seulement 5% à 10% des photos et
          vidéos prises sont publiées par les utilisateurs qui souhaitent ne montrer que le
          meilleur. Les 90% restants dorment dans leurs smartphones et sont généralement partagées
          avec des applications de messagerie qui ne sont pas construites pour une navigation et
          une expérience utilisateur centrées sur la photo ou la vidéo. Je lance l’app Albums pour
          préserver la vie privée de millions de personnes et leur donner les outils appropriés
          pour enfin partager et contrôler les milliers de photos et vidéos qu’ils génèrent chaque
          jour ” souligne Taïg Khris
        </ArticleParagraph>
        <ArticleTitle level={2}>
          Gagner de l’espace sur son smartphone
        </ArticleTitle>
        <ArticleParagraph>
          Les utilisateurs peuvent stocker via l’app Albums la totalité de leurs photos et vidéos
          pour ne jamais les perdre et cela sans en réduire la qualité puisqu’il est possible
          d’acheter de l’espace de stockage dans le Cloud crypté d’Albums. Une fois le contenu
          synchronisé et stocké, les utilisateurs peuvent, s’ils le souhaitent, libérer de
          l’espace dans leurs smartphones en effaçant les photos et vidéos qui ont été
          synchronisées.
        </ArticleParagraph>
        <ArticleParagraph>
          À l’heure où le nouvel iPhone dispose de deux objectifs, d’un ultra grand-angle et d’une
          définition 4K pour les photos et les vidéos, pouvoir sauvegarder ses contenus facilement
          depuis une application devient primordial. Les 5 premiers gigas seront gratuits pour les
          utilisateurs. Au delà, ces derniers auront le choix de réduire la qualité et continuer à
          utiliser l’app Albums totalement gratuitement ou acheter de l’espace de stockage
          supplémentaire pour garder en qualité originale ses photos et vidéos (cf grille prix
          annexe).
        </ArticleParagraph>
        <figure className={styles.image}>
          <GatsbyImage fluid={image3.childImageSharp.fluid} />
        </figure>
        <ArticleTitle level={2}>
          Albums : un réseau social Made In Europe
        </ArticleTitle>
        <ArticleParagraph>
          Pour réaliser le meilleur réseau social de partage de photos sur mobile, Taïg Khris
          s’est appuyé sur l’expérience acquise avec sa société Onoff. Avec ses équipes basées à
          Paris (France) et Tallinn (Estonie), il veut créer le premier réseau social made in
          Europe, aux ambitions internationales. En plus de garantir un réseau crypté, Albums
          sauvegarde les données sur le sol européen.
        </ArticleParagraph>
        <ArticleParagraph>
          L’application Onoff, déjà lancée par l’équipe, permet de se créer plusieurs numéros de
          téléphone sur un même smartphone. Elle compte déjà plusieurs millions de téléchargements
          et enregistre une croissance de plus 100% du chiffre d’affaires depuis début 2019.
          L’ancien champion des sports extrêmes est devenu au fil des 5 dernières années un expert
          dans le monde applicatif.
        </ArticleParagraph>
        <ArticleParagraph>
          C’est avec cette expérience et cette vision que Taïg Khris a imaginé et créé ce nouveau
          réseau social. L’app Albums est une application au design simple et élégant, d’une
          grande stabilité et simplicité de navigation, tout en ayant de nombreuses
          fonctionnalités uniques et innovantes.
        </ArticleParagraph>
        <div className={styles.highlited}>
          L’application Albums sera lancée d’abord sur iPhone, iPad et puis Mac !
        </div>
        <div className={styles.separator} />
        <ArticleTitle level={3}>
          À propos d’Albums
        </ArticleTitle>
        <ArticleParagraph>
          Albums est une application lancée par la société Onoff Télécom. Cet opérateur
          télécom international développe également une application mobile permettant de
          générer plusieurs numéros - français ou internationaux - sur son mobile. Créée et
          dirigée par Taïg Khris, la société s’adresse aux professionnels comme aux
          particuliers. Onoff a bénéficié d’une levée de fonds de 20M€. Le siège social
          d&apos;onoff Télécom est à Paris et la société dispose également de bureaux en
          Belgique et en Estonie.
        </ArticleParagraph>
        <ArticleTitle level={3}>
          À propos de Taïg Khris
        </ArticleTitle>
        <ArticleParagraph>
          Taïg Khris est le sportif le plus titré de l’histoire des sports extrêmes. En roller,
          il a battu plusieurs records du monde dont celui du saut dans le vide depuis la Tour
          Eiffel, et celui du saut en longueur au Sacré Cœur. Animateur télévisé sur W9, Gulli et
          MCS, il participe à de nombreuses émissions en prime time, dont Fort Boyard et Danse
          avec les stars, et remporte même l’édition célébrités de Pékin Express en 2010. Depuis
          2014, c’est en businessman accompli qu’il poursuit sa carrière, dans la téléphonie, avec
          l&apos;app onoff, qui permet de se créer un n° international en 2 clics et à moindre
          coût. Elu au board de France Digitale, il met également sa notoriété au service la
          French Tech.
        </ArticleParagraph>
        <div className={styles.contact}>
          <ArticleTitle level={3}>
            Contacts Presse
          </ArticleTitle>
          <div>
            <span>Allison Reber</span>
            {' '}
            –
            {' '}
            <a href="mailto:allison.r@wesprint.fr">allison.r@wesprint.fr</a>
            {' '}
            –
            {' '}
            <a href="tel://+330615643983">06 15 64 39 83</a>
          </div>
          <div>
            <span>Arnaud Laurent</span>
            {' '}
            –
            {' '}
            <a href="mailto:arnaud@onoffapp.com">arnaud@onoffapp.com</a>
            {' '}
            –
            {' '}
            <a href="tel://+330668278127">06 68 27 81 27</a>
          </div>
        </div>
        <ArticleTitle level={2}>
          Annexes
        </ArticleTitle>
        <ArticleTitle level={3}>
          Liste des fonctionnalités d’Albums:
        </ArticleTitle>
        <ul>
          <li>Les albums publics, visibles par tous.</li>
          <li>Les albums privés cryptés, visibles par personne.</li>
          <li>
            Les albums partagés en lecture seule (Restricted Access) illimitée en temps et en
            nombre d’ouvertures.
          </li>
          <li>
            Les albums partagés en lecture seule (Restricted Access) limitée en temps
            (5min,15min, 30min, 1h, 3, 6h, 1j, 3j, 7, 15, 1mois, 3mois, 1an).
          </li>
          <li>
            Les albums partagés en lecture seule (Restricted Access) limitée en nombre
            d’ouvertures (de 1 à 50) - Disponible dans les prochaines mises à jour.
          </li>
          <li>
            Les albums en lecture partagée permettant à chaque membre de poster ses photos et
            vidéos (Full Access).
          </li>
          <li>L’ajout de mot de passe pour chaque album privé.</li>
          <li>Le stockage illimité gratuit des photos et vidéos (en basse definition).</li>
          <li>
            Le stockage des photos et vidéos en HD (qualité originale) gratuit jusqu&apos;à 10
            GB.
          </li>
          <li>
            Le stockage des photos et vidéos en HD (qualité originale) au-delà des 10 GB via
            l’achat d’espace de stockage.
          </li>
          <li>La suspension des comptes au-delà d’une capture d’écran.</li>
          <li>L’ajout des Likes sur les photos et vidéos.</li>
          <li>
            L’ajout de commentaires sur les photos et vidéos - Disponible dans les prochaines
            mises à jour.
          </li>
          <li>La liaison avec le compte Instagram afin de le rendre disponible sur le profil.</li>
          <li>
            La possibilité d’ajouter directement les photos et vidéos dans les albums depuis son
            compte Instagram.
          </li>
          <li>
            La possibilité de personnaliser son profil avec du texte, des liens et une photo
            portrait.
          </li>
          <li>
            Le Dark Mode ou plutôt le mode bleu nuit qui permet de changer la couleur de l’app
            et la rendre sombre pour une utilisation dans le noir - Disponible dans les prochaines
            mises à jour.
          </li>
          <li>Création de compte via email et Apple Sign up.</li>
          <li>Le reseau social de followers et following.</li>
          <li>Les badges certifiés pour les célébrités et les marques.</li>
          <li>
            Le bouton Discover qui permet de découvrir par thème (Music, Fashion, Sport, Art,
            Travels, etc.) tous les plus beaux profils et contenus d’albums présents sur le réseau
            social de l’app Albums.
          </li>
          <li>
            Le bouton Search permet de chercher n’importe qui sur le réseau social d’Albums et
            de recevoir des suggestions d’amis, de followers ou de comptes certifiés.
          </li>
          <li>
            Le bouton Timeline qui permet de suivre sur un flux d&apos;actualité tous les
            comptes auxquels nous sommes abonnés.
          </li>
          <li>
            La possibilité de changer l’ordre des albums ou l’ordre des photos dans les albums à
            volonté.
          </li>
        </ul>
        <ArticleTitle level={3}>
          Exemples d’usages / illustrations:
        </ArticleTitle>
        <ul>
          <li>
            <strong>Exemple album partagé: </strong>
            L’utilisateur crée un album privé qu’il nomme “Vacance entre copains” et qui n’est
            visible par personne sur le réseau Albums sauf la liste d’amis qu’il a un par un
            sélectionné et qui ont accepté son invitation. De cette façon, ce groupe de copains a
            accès à un album commun de vacances pour poster leurs photos et vidéos. Chaque membre
            peut poster ses photos et vidéos dans cet album commun et les autres membres peuvent
            voir qui a posté et peuvent interagir via des likes et des commentaires. Ce type
            d’album partagé peut être très pratique pour créer un album familial visible
            uniquement par les membres de la famille ou uniquement un album pour sa copine ou son
            copain pour partager des photos et vidéos que personne d’autre ne pourra voir. Mais
            ces albums partagés peuvent aussi l’être pour un grand nombre de membres comme dans le
            cas d’un mariage, un anniversaire ou même un album interne d’une entreprise.
          </li>
          <li>
            <strong>Exemple album partagé en lecture seule: </strong>
            L&apos;utilisateur crée un album privé avec du contenu sensible, de type photos
            personnelles ou professionnelles. Il peut alors donner un accès en lecture seule à
            certaines personnes. Ces personnes ne vont pas pouvoir poster des photos dans l’album
            ou voir quels sont les autres membres. Ils ne vont pas pouvoir faire de capture
            d’écran. Ils peuvent juste consulter le contenu.
            <br />
            Le propriétaire de l’album pourra, s’il le souhaite, donner un accès illimité en
            temps ou en nombre d’ouvertures, ou au contraire donner un accès limité de type 30
            minutes, 1h, 1 jour etc. Ou un accès pour ouvrir l’album uniquement 1 fois, ou 3
            fois ou 20 fois, etc. De cette façon le propriétaire de l’album peut donner
            différents types d’accès au sein du même album. Un accès restreint en lecture seule
            (avec une durée ou pas) ou un accès avec droit de modification
          </li>
          <li>
            <strong>Exemple pour stocker ses photos et vidéos: </strong>
            L&apos;utilisateur crée un album (privé ou public) et sélectionne la totalité des
            photos et
            vidéos de son smartphone. L’app Albums synchronise en wifi ou via la data du téléphone
            tous les contenus dans le cloud crypté de Albums. Une fois la synchronisation
            terminée, l’utilisateur pourra, s’il il le souhaite, effacer des contenus de son
            téléphone sans prendre le risque de les perdre puisque tout sera totalement sauvegardé
            dans le cloud de l’app Albums (il pourra à tout moment les re-télécharger dans son
            smartphone). Il pourra aussi mieux organiser ses photos et vidéos dans des albums
            séparés pour les visionner et les retrouver plus facilement. Si la quantité de photos
            et vidéos dépasse les 10 gigas gratuits de l’app Albums, l’utilisateur pourra acheter
            du stockage supplémentaire dans les réglages de l’app Albums.
          </li>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <td>Stockages</td>
                  <td>Prix par mois</td>
                  <td>Prix par an</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>100 GB</td>
                  <td>2,29€</td>
                  <td>19,99€</td>
                </tr>
                <tr>
                  <td>200 GB</td>
                  <td>3,49€</td>
                  <td>29,99€</td>
                </tr>
                <tr>
                  <td>2 TB</td>
                  <td>10,99€</td>
                  <td>94,99€</td>
                </tr>
                <tr>
                  <td>10 TB</td>
                  <td>99,99€</td>
                  <td>799,99€</td>
                </tr>
                <tr>
                  <td>20 TB</td>
                  <td>199,99€</td>
                  <td>1699,99€</td>
                </tr>
                <tr>
                  <td>30 TB</td>
                  <td>299,99€</td>
                  <td>2499,99€</td>
                </tr>
              </tbody>
            </table>
          </div>
          <li>
            <strong>
              Exemple pour protéger et cacher ses albums à l’aide d’un mot de passe:
              {' '}
            </strong>
            L&apos;utilisateur qui a des photos privées et qui ne veut pas les mélanger avec les
            photos de son smartphone peut créer un album privé, y ajouter ses photos et vidéos
            privés, puis les effacer de son smartphone et enfin ajouter un mot de passe sur
            l’album pour protéger le tout. Une fois le mot de passe ajouté, l’utilisateur peut à
            tout moment rendre cette album invisible en appuyant sur l&apos;icône en forme d’oeil
            sur l’album et le faire apparaître de nouveau à l’aide du mot de passe.
          </li>
        </ul>
      </ArticleContent>
    </Article>
  );
};

export default PressRelease;
