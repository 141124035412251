import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';

import PressReleaseEN from '../articles/en/PressRelease';

const PressPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Press</title>
      <meta name="og:title" content="Press" />
      <meta name="twitter:title" content="Press" />
    </Helmet>
    <PressReleaseEN />
  </Layout>
);

export default PressPage;
